import { IoIosArrowUp } from "react-icons/io";

export const MapSection = () => {
  return (
    <div>
      <div className="relative mt-5">
        <img src="/assets/map.png" alt="map" className="cover w-full" />
        <a
          href="#top"
          className="h-[50px] w-[137px] bg-blk absolute bottom-0 right-[35%] lg:right-[45%] flex justify-center"
        >
          <IoIosArrowUp className="text-white text-3xl text-center  mt-3 " />
        </a>
      </div>
    </div>
  );
};

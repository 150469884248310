import React from "react";

const Bgreact = (props) => {
  // const myStyle = {
  //   width: "100%",
  //   // height: "100vh",
  //   backgroundImage: 'url("/assets/school.jpg")',
  //   backgroundSize: "cover",
  //   backgroundPosition: "center",
  // };
  return (
    <div className="mt-4">
      <div>
        <div>
          <img src={props.img} className="bg-cover w-full" />
          <div className=" text-center font-semibold text-dark-text ">
            {props.title}
          </div>
        </div>
      </div>

      <p className="text-center font-semibold  text-light-text text-sm mb-8">
        {props.content}
      </p>
    </div>
  );
};

export default Bgreact;

import { LifeAtArniko } from "../../components/modules/Landing/LifeAtArniko";
import { JoinUsSection } from "../../components/modules/Landing/JoinUsSection";
import { Admissions } from "../../components/modules/Landing/Admissions";
import { WhyUs } from "../../components/modules/Landing/WhyUs";
import { MapSection } from "../../components/modules/Landing/MapSection";
import { HeroSection } from "../../components/modules/Landing/HeroSection";

export const LandingPage = () => {
  return (
    <main className="font-pop overflow-x-hidden ">
      <HeroSection />
      <JoinUsSection />
      <Admissions />
      <LifeAtArniko />
      <WhyUs />
      <MapSection />
    </main>
  );
};

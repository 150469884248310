import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SideMenu = () => {
  const navigate = useNavigate();
  const INITIAL_SIDEBAR_ITEMS = [
    {
      label: "Primary Years Curriculum ",
      link: "/primary",
    },
    {
      label: "Middle Years Curriculum ",
      link: "/middle",
    },
    {
      label: "Senior Years Curriculum ",
      link: "/senior",
    },
    // {
    //   label: "Socio-Cultural Values",
    //   link: "/culture",
    // },
  ];
  const [sidebarItems, setSidebarItems] = useState([...INITIAL_SIDEBAR_ITEMS]);
  return (
    <div>
      <ul className="text-lg text-dark-text cursor-pointer ">
        {sidebarItems.map((v, key) => (
          <li
            key={key}
            className="  font-medium text-xl p-4 hover:text-gray hover:translate-x-6  hover:ease-in-out duration-300"
            onClick={() => navigate(v.link)}
          >
            {v.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;

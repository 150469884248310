const Ads = () => {
  return (
    <div>
      <h2 className="text-2xl  text-arniko-red ">Arts at ICS</h2>
      <p>Read it online below.</p>

      <div className="discoverMore_box py-14 bg-arniko-red w-full text-center  hover:bg-dblue ">
        <h1 className="text-white font-bold text-3xl pt-1 ">Discover more</h1>
        <h1 className="text-white font-bold text-3xl  pt-1 ">
          about Admissions
        </h1>
        <h1 className="text-white font-bold text-3xl  pt-1 ">at Arniko</h1>
      </div>

      <hr className="my-9 text-hr" />

      <div className="quote">
        <h1 className="text-sm font-light p-2 text-arniko-red">
          "When asked to name some of the things she remembers about ICS,
          Georgina has fond memories. She credits ICS with developing her sense
          of solidarity and team spirit, with fostering her interests in sports
          and arts,"
        </h1>
        <h1 className=" font-light text-sm p-2 text-light-text">
          Linus T, Grade 3 student
        </h1>
      </div>

      <hr className="my-9 text-hr" />

      <div className="discoverMore_box py-9 bg-dblue w-full text-center font-normal text-2xl  hover:bg-arniko-red ">
        <h1 className="text-white  pt-1 ">Find out how</h1>
        <h1 className="text-white pt-1 ">to apply</h1>
      </div>

      <hr className="my-9 text-hr" />

      <div className="quote">
        <h1 className="text-sm font-light p-2 text-arniko-red">
          My theme throughout this year has been the empowerment of women. I see
          art as a way of empowering women to be who they are, to show their
          individuality and to help them make a change. I hope to inspire women
          throughout my art.
        </h1>
        <h1 className=" font-light text-sm p-2 text-light-text">
          Florence F. Grade 12 student
        </h1>
      </div>

      <hr className="my-9 text-hr" />
    </div>
  );
};

export default Ads;

import { AiOutlinePauseCircle } from "react-icons/ai";
import { BsArrowDownCircle, BsFillVolumeMuteFill } from "react-icons/bs";
import Filled from "../../common/Filled";

export const HeroSection = () => {
  const myStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: 'url("/assets/main.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div>
      <div className="flex flex-col justify-end h-screen" style={myStyle}>
        <div className="bottom__nav p-4">
          <div className="lg:flex lg:justify-between">
            <BsFillVolumeMuteFill className="h-10 w-10 text-white" />
            <div className="wrapper lg:flex lg:gap-2 md:flex md:gap-2">
              <div className="flex flex-col justify-center p-1">
                <Filled tit="ARRANGE A VISIT" />
              </div>
              <div className="flex flex-col justify-center p-1">
                <Filled tit="REQUEST INFO" />
              </div>
            </div>
            <div className="wrapper invisible flex gap-1 lg:visible">
              <a href="#bottom">
                <BsArrowDownCircle className="h-10 w-10 text-white  " />
              </a>
              <AiOutlinePauseCircle className="h-10 w-10 text-white " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

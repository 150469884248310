const MainImg = () => {
  return (
    <div>
      <section
        className="w-full px-lg flex items-center"
        style={{
          backgroundImage:
            "url('https://images.pexels.com/photos/6280718/pexels-photo-6280718.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load')",
          height: "40vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          minHeight: "400px",
        }}
      >
        <div className="text-2xl text-white">Title</div>
      </section>
    </div>
  );
};

export default MainImg;

function Graybox(props) {
  return (
    <>
      <div className="p-9 bg-gray h-full text-white text-center lg:mt-12 ">
        <div className=" flex flex-col gap-3 justify-around items-center text-6xl">
          <p>{props.icon}</p>
          <h2>{props.number}</h2>
          <p className="text-base font-normal">{props.info}</p>
        </div>
      </div>
    </>
  );
}

export default Graybox;

import {
  Alert,
  Button,
  FileInput,
  Grid,
  Group,
  Input,
  Paper,
  Radio,
  Select,
  Text,
  Textarea,
} from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { DatePicker } from "@mantine/dates";
import { IconAlertCircle, IconUpload } from "@tabler/icons-react";
import { InputWrapper } from "@mantine/core/lib/Input/InputWrapper/InputWrapper";
// import { RadioGroup } from "@mantine/core/lib/Radio/RadioGroup/RadioGroup";

const Form = (onDrop) => {
  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e) {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    onDrop(files);
  }
  return (
    <div className="w-[60%] font-pop text-sm mx-auto py-4 ">
      <Paper shadow="xl" radius="md" p="md">
        <form>
          <div className="details text-center mb-6 ">
            <Text className=" font-pop text-arniko-red lg:text-2xl">
              ARNIKO INTERNATIONAL SECONDARY SCHOOL
            </Text>
            <Text className="text-xs font-pop">
              KUMARIPATI, LALITPUR
              <br />
              01-5408653 | arnikocollege@gmail.com
            </Text>
          </div>

          <Text className="text-arniko-red py-3 font-pop">
            Fields with (*) are compulsory.
          </Text>

          <div className="applied">
            <Radio.Group
              name="favoriteFramework"
              label="STREAM / PROGRAMME APPLIED FOR "
              withAsterisk
              className="py-4"
            >
              <Group mt="xs">
                <Radio value="Law" label="Law" />
                <Radio value="Management" label="Management" />
                <Radio value="Humanities" label="Humanities" />
                <Radio value="Science" label="Science" />
              </Group>
            </Radio.Group>

            <Grid className="pb-10">
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" withAsterisk label="First Name">
                  <Input id="input-demo" placeholder="First Name" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" label="Middle Name">
                  <Input id="input-demo" placeholder="Middle Name" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" withAsterisk label="Last Name">
                  <Input id="input-demo" placeholder="Last Name" />
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </div>

          <div className="personal">
            <Text className=" bg-gray  text-center text-lg font-pop py-1 text-dblue">
              PERSONAL INFORMATION
            </Text>

            <Grid className="py-6">
              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <div>
                  <Input.Wrapper label="Date of birth " required>
                    <DatePicker
                      placeholder="YYYY-MM-DD"
                      label="BS"
                      className="my-2"
                    />
                    <DatePicker
                      placeholder="YYYY-MM-DD"
                      label="AD"
                      className="my-2"
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <Radio.Group label="Gender" required>
                  <Radio value="Male" label="Male" />
                  <Radio value="Female" label="Female" />
                  <Radio value="Other" label="Other" />
                </Radio.Group>
              </Grid.Col>
              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <Select
                  label="Nationality"
                  placeholder="Nepali"
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  data={["React", "Angular", "Svelte", "Vue"]}
                />
              </Grid.Col>
              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" label="Email">
                  <Input id="input-demo" placeholder="Your email" />
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" withAsterisk label="Address">
                  <Input id="input-demo" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <Input.Wrapper
                  id="input-demo"
                  withAsterisk
                  label="Mobile Number"
                >
                  <Input id="input-demo" />
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <Select
                  label="Province:"
                  placeholder="--Select--"
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  data={["React", "Angular", "Svelte", "Vue"]}
                />
              </Grid.Col>
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <Select
                  label="District:"
                  placeholder="--Select--"
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  data={["React", "Angular", "Svelte", "Vue"]}
                />
              </Grid.Col>
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <Select
                  label="Municipality:"
                  placeholder="--Select--"
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  data={["React", "Angular", "Svelte", "Vue"]}
                />
              </Grid.Col>
            </Grid>
          </div>

          <div className="academic">
            <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
              ACADEMIC INFORMATION
            </Text>
            <Grid className="pb-9 pt-8">
              <Grid.Col lg={6} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" label="Previous School's Name :">
                  <Input id="input-demo" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col lg={3} md={6} sm={6} xs={0}>
                <Input.Wrapper id="input-demo" label="Appeared Year:">
                  <Input id="input-demo" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col lg={3} md={6} sm={6} xs={0}>
                <Input.Wrapper
                  id="input-demo"
                  withAsterisk
                  label="Percentage/GPA:"
                >
                  <Input id="input-demo" />
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </div>

          <div className="parents">
            <div className="father">
              <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
                PARENT'S INFORMATION
              </Text>
              <Grid className="pb-9 pt-8">
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Input.Wrapper id="input-demo" label="Father's Name">
                    <Input id="input-demo" />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Input.Wrapper id="input-demo" label="Mobile Number">
                    <Input id="input-demo" />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Select
                    label="Nationality"
                    placeholder="--Select--"
                    searchable
                    withAsterisk
                    nothingFound="No options"
                    data={["React", "Angular", "Svelte", "Vue"]}
                  />
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Input.Wrapper id="input-demo" label="Address">
                    <Input id="input-demo" />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </div>
            <hr className="text-hr" />
            <div className="mothers">
              <Grid className="pb-9 pt-8">
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Input.Wrapper id="input-demo" label="Mother's Name">
                    <Input id="input-demo" />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Input.Wrapper id="input-demo" label="Mobile Number">
                    <Input id="input-demo" />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Select
                    label="Nationality"
                    placeholder="--Select--"
                    searchable
                    withAsterisk
                    nothingFound="No options"
                    data={["React", "Angular", "Svelte", "Vue"]}
                  />
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6} xs={0}>
                  <Input.Wrapper id="input-demo" label="Address">
                    <Input id="input-demo" />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </div>
          </div>

          <div className="docs">
            <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
              SUPPORTING DOCUMENTS
            </Text>

            <Grid className="pb-9 pt-8">
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <div
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  style={{
                    border: "1px dashed gray",
                    padding: "1rem",
                    textAlign: "center",
                  }}
                >
                  Drag and drop files here
                </div>
              </Grid.Col>
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <FileInput
                  label="Document"
                  placeholder="No file chosen"
                  icon={<IconUpload />}
                />
              </Grid.Col>
              <Grid.Col lg={4} md={6} sm={6} xs={0}>
                <FileInput
                  label="Others(if any)"
                  placeholder="No file chosen"
                  icon={<IconUpload className="text-4" />}
                />
              </Grid.Col>
            </Grid>
            <div className="alert py-4">
              <Alert
                icon={<IconAlertCircle size="1rem" />}
                color="gray"
                variant="filled"
              >
                Please click on the image to upload or change it. Accepted file
                size: Max 1MB.
              </Alert>
            </div>
          </div>

          <div className="others">
            <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
              OTHERS
            </Text>
            <Textarea label="Write your query(if any):" className="pb-9 pt-8" />
          </div>
          <div className="submit flex justify-center">
            <Button
              className="bg-arniko-red font-pop font-normal text-base hover:bg-dblue text-center"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default Form;

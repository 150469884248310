import { Grid } from "@mantine/core";

import Information from "../../common/Information";
import School from "../../common/School";

export const JoinUsSection = () => {
  const gridcontent = [
    {
      title: "PRESCHOOL/KINDERGARTEN",
      content:
        "Nursery, Pre-K to K2. An engaging programme builds an intellectual foundation",
      img: "/assets/section1.png",
    },
    {
      title: "PRIMARY SCHOOL",
      content:
        "Grades 1-5. A rigorous inquiry-based curriculum stimulates authentic conceptual learning ",
      img: "/assets/section2.png",
    },
    {
      title: "MIDDLE SCHOOL",
      content:
        " Grades 6-10. Self-directed learning develops originality and resilience, plus deep enduring understanding",
      img: "/assets/section3.png",
    },
    {
      title: "DIPLOMA STUDIES",
      content:
        "Grades 11-12. A demanding curriculum prepares students for top international university admission ",
      img: "/assets/section4.png",
    },
  ];
  return (
    <div>
      <div className="mt-12">
        <Information
          title="Join Arniko"
          content="Welcome to Arniko Int’l SS & College, We are a unique and vibrant learning community, dedicated to providing a high-quality education and a safe, supportive environment for all of our students. We value ourselves as catering the needs of our students on a one to one basis, delivered through a range of exciting and stimulating activities. Our curriculum focuses on the overall development of the child, equipping them with the knowledge, skills and attitudes they need to flourish in their future, both inside and outside of the classroom.
        "
        />
      </div>

      <Grid>
        {gridcontent.map((data, index) => (
          <Grid.Col md={6} lg={3} key={index} className="p-1">
            <School title={data.title} content={data.content} img={data.img} />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};

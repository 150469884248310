import Nav from "../components/common/Nav";
import { Routes, Route } from "react-router-dom";
import MainRoute from "../routes";
import Footer from "../components/common/Footer";
export const LandingLayout = () => {
  return (
    <main className="font-pop">
      <Nav />
      <MainRoute />
      <Footer />
    </main>
  );
};

import React from "react";
import Features from "../../components/common/Features";
import Filled from "../../components/common/Filled";

const Science = () => {
  return (
    <>
      <h1 className=" text-4xl  font-normal text-center">
        The Senior Years Curriculum
      </h1>
      <p className="text-base font-normal text-center p-3">
        With modern technological advancement, we believe every child should
        have the opportunity to learn practical, scientific, and technological
        tools rather than being limited to textbook knowledge.
      </p>
      <div className="my-12 ">
        <img src="/assets/hero.png" alt="image" className=" bg-cover w-full" />
      </div>
      <p className="text-base font-normal">
        ICS offers a comprehensive Arts Programme across the Primary and
        Secondary Schools, providing rich opportunities for the artistic,
        intellectual and academic development of our students. The ICS
        difference is found in the integration of the Arts with other academic
        areas and in the rigorous disciplinary studies found in the
        <br />
        <br />
        Recent studies have demonstrated the social and emotional benefits in
        studying the Arts across all areas of learning. It has been found that
        students who frequently and actively engaged with the Arts in schools -
        as makers and doers of the Arts - tended to be more academically engaged
        and more motivated in school subjects.
      </p>
      <hr className="my-9 text-hr" />
      <div className="quote">
        <h1 className="text-lg font-light p-2 text-arniko-red">
          "When asked to name some of the things she remembers about ICS,
          Georgina has fond memories. She credits ICS with developing her sense
          of solidarity and team spirit, with fostering her interests in sports
          and arts, and with helping her to step out into the world beyond "with
          openness and tolerance that I often miss these days."
        </h1>
        <h1 className=" font-light text-lg p-2">
          Interview with Georgina C, Class of 2000, Art Curator
        </h1>
      </div>
      <hr className="my-9 text-hr" />

      <div className="content">
        <div className="literacy">
          <h2 className="text-4xl mb-3 text-dblue py-3">Literacy</h2>
          <div className="develop oral language">
            <h2 className="text-xl  text-arniko-red ">
              Developing oral language (listening and speaking)
            </h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li> listen and enjoy interacting during read aloud</li>
                <li>talk about and tell elaborate stories they have created</li>
                <li> carry out directions with five to six steps</li>
                <li>
                  use new, less familiar, technical words in everyday
                  conversations
                </li>
                <li> pronounce multisyllabic words or technical words</li>
                <li> use common verbs and nouns</li>
                <li>
                  engage in complex, lengthy conversations of 5 or more
                  exchanges
                </li>
              </ol>
            </p>
          </div>

          <div className="Developing visual communication py-4 ">
            <h2 className="text-xl  text-arniko-red ">
              Developing visual communication (viewing and presenting)
            </h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  observe and comment on illustrations in picture books and
                  simple reference books
                </li>
                <li>
                  show awareness of the terminology, e.g. illustration, text and
                  layout
                </li>
                <li>
                  use body language in mime, role play to communicate ideas
                  visually, e.g features, layout, border and frame
                </li>
                <li>
                  recognize that shapes, symbols, colors have meaning and
                  include them in presentations
                </li>
              </ol>
            </p>
          </div>
          <div className="Developing as readers py-4  ">
            <h2 className="text-xl  text-arniko-red ">Developing as readers</h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li> participate in guided reading situations </li>
                <li>
                  recognize an increasing bank of high-frequency and
                  high-interest words, characters or symbols
                </li>
                <li>
                  know most sound-symbol relationships and apply reliable
                  phonetic strategies when decoding print
                </li>
                <li> blend simple cvc words, e.g. ’c’ ‘a’ ‘t’ reads as cat</li>
                <li> recognize sounds in words</li>
                <li>
                  self-monitor and self-correct using strategies, e.g. meaning,
                  visual, contextual and memory cues
                </li>
                <li>
                  identify and explain the basic structure of a story, e.g.
                  beginning, middle and end
                </li>
              </ol>
            </p>
          </div>
          <div className="	Developing as writers py-4 ">
            <h2 className="text-xl  text-arniko-red ">Developing as writers</h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li> write simple sentences, e.g. I like… I see...</li>
                <li> write for different topics, audiences and purposes</li>
                <li> hold pencil correctly and legibly form letters</li>
                <li> write familiar words independently</li>
                <li> sound out new words when writing</li>
                <li>
                  sound out new words when writing represent words using 1, 2 or
                  3 letters, e.g. party as ‘prt’
                </li>
              </ol>
            </p>
          </div>
        </div>

        <div className="	Numeracy py-6">
          <h2 className="text-4xl text-dblue py-1">Numeracy</h2>

          <p className="text-base py-3">
            <ol className="list-disc">
              <li>
                count, forwards and backwards, in the range 0–20 and beyond
              </li>
              <li>
                skip-count sequences, forwards and backwards, in the range 0–20
                for twos and fives
              </li>
              <li> group numbers within 5 and 10, e.g. 2 and 3, 4 and 1</li>
              <li>
                recognize patterns to 10 (doubles and 5-based), including finger
                patterns
              </li>
              <li>
                recall simple addition and subtraction facts to five and beyond
              </li>
              <li> create simple and complex patterns with objects</li>
              <li>
                use non-standard units of measurement to solve problems in
                real-life situations involving length, mass and capacity
              </li>
              <li>
                explore and describe position and direction within their
                environment
              </li>
              <li>
                show and compare data through living graphs, pictographs and
                tally marks
              </li>
            </ol>
          </p>
        </div>
        <div className="Sanskrit Language py-6">
          <h2 className="text-4xl text-dblue py-1"> Sanskrit Language</h2>

          <p className="text-base py-3">
            <ol className="list-disc">
              <li>
                use a broader selection of words to communicate needs and to
                express ideas
              </li>
              <li>follow simple instructions with more than one direction</li>
              <li> know some common verbs and adjectives</li>
              <li> listen and respond to stories, questions and statements</li>
              <li>
                ask simple questions relevant to the classroom and community
              </li>
              <li> recognize sounds in words </li>
              <li> write some high frequency words </li>
              <li> read some high frequency words in context </li>
            </ol>
          </p>
        </div>
        <div className="	Social Emotional py-6">
          <h2 className="text-4xl text-dblue py-1"> Social Emotional</h2>

          <p className="text-base py-3">
            <ol className="list-disc">
              <li>
                increasingly able to recognise and manage their own feelings and
                begin to develop empathy for others when interacting
              </li>
              <li>
                function with increasing independence and self-direction when
                completing activities
              </li>
              <li>
                participate in groups, taking on different roles and
                responsibilities
              </li>
              <li>
                recognize that others have emotions, feelings and perspectives
                that may be different from their own
              </li>
              <li>
                understand the importance of looking after themselves and their
                environment
              </li>
              <li>
                take risks, engage in new physical experiences and be confident
                when learning by trial and error
              </li>
            </ol>
          </p>
        </div>

        <div className="Science & Technology py-6">
          <h2 className="text-4xl text-dblue py-1"> Science & Technology</h2>

          <p className="text-base py-3">
            <ol className="list-disc">
              <li> manipulate objects to observe and explore properties </li>
              <li> record data using simple pictures and charts</li>
              <li>
                choose appropriate tools during scientific investigations to
                make comparisons of measurement between mass, weight and
                temperature
              </li>
              <li>
                discuss what is happening in a scientific investigation and
                share findings using scientific vocabulary
              </li>
              <li>
                recall scientific investigations by identifying the problem
                investigated and suggest next steps
              </li>
              <li>
                begin to think of the ways they can solve scientific problems
                and begin to identify variables within a scientific
                investigation
              </li>
              <li>
                predict a reasonable outcome to test, observe and collect data
                on during scientific investigation
              </li>
              <li>
                interpret information from a scientific investigation and offer
                their own explanations and compare results from other
                investigations
              </li>
              <li>
                orally recount steps in a scientific investigation to answer a
                specific question and demonstrate their understanding using
                drawings and flow charts
              </li>
              <li>
                Apply new scientific understanding to the new current context.
              </li>
            </ol>
          </p>
        </div>

        <div className="	Creative Arts py-6">
          <h2 className="text-4xl mb-3 text-dblue py-3"> Creative Arts</h2>
          <div className="	Visual Arts">
            <h2 className="text-xl  text-arniko-red ">Visual Arts</h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li> recognize that artwork has meaning</li>
                <li>
                  take responsibility for their own safety and the safety of
                  others and tools and materials
                </li>
                <li> analyze the relationships within an artwork </li>
                <li>
                  identify the materials and processes used in the creation of
                  artwork
                </li>
              </ol>
            </p>
          </div>

          <div className="	Dance/Drama py-2">
            <h2 className="text-xl  text-arniko-red "> Dance/Drama</h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li>communicate and express feelings through body movement</li>
                <li>
                  explore the dynamic flow of body movements such as fast, slow,
                  big, small, strong, smooth and sharp tension
                </li>
                <li>
                  identify and explain why certain body postures and movements
                  communicate ideas, feelings
                </li>
                <li>
                  respond to dramatic ideas through spoken, visual, auditory and
                  kinaesthetic mediums
                </li>
              </ol>
            </p>
          </div>
          <div className="	Music py-2  ">
            <h2 className="text-xl  text-arniko-red "> Music</h2>
            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  express responses to music in multiple ways, e.g. drawing,
                  games, song, dance, discussion
                </li>
                <li>
                  participate in performing and creating music individually and
                  collectively
                </li>
              </ol>
            </p>
          </div>
        </div>

        <div className="		Design Thinking py-6	">
          <h2 className="text-4xl text-dblue py-1"> Design Thinking</h2>

          <p className="text-base py-3">
            <ol className="list-disc">
              <li>
                understand how making and testing predictions can help to
                evaluate a product or process and improve it
              </li>
              <li>
                know they can document, record and note ideas and use specific
                vocabulary to support a scientific or creative design process
              </li>
              <li> be able to learn new skills to support their designs</li>
              <li>
                experience how their designs can have a positive impact on their
                class or a real-life situation
              </li>
            </ol>
          </p>
        </div>
      </div>
      {/* <Features
        title="Performing Arts and Visual Arts"
        content={`At ICS we understand and value the importance of the Arts in
both a student’s academic and personal life. Students being
creative, expressing themselves, communicating with others,
collaborating, and solving problems is a key component of the
International Baccalaureate Programme. From our preschool
programme through to Grade 12, Music, Drama, Performing Arts
and Visual Arts play a significant role in the academic
curriculum.`}
      />
      <Features
        title="Extra-curricular Programme"
        content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
      />
      <Features
        title="Primary School"
        content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
      />
      <Features
        title="Secondary School"
        content={`School provide continuity from the Primary School. Students explore the Arts as a means of both self-expression and, importantly, the expression of ideas. They continue to develop their understanding of diverse cultural traditions and the function of Arts in their own communities. Teamwork and collaboration also continue to be important points of focus and the risk-taking aspect of the Arts is enhanced through greater opportunities to performances for the school community.`}
      /> */}
      <div className="text-center py-4 mb-9">
        <h2 className="text-2xl  text-arniko-red ">
          Unleash your child's artistic voice
        </h2>
        <div className="flex gap-2 justify-center py-4">
          <Filled tit="ADMISSIONS" />
          <Filled tit="PLAN A VISIT" />
        </div>
      </div>
    </>
  );
};

export default Science;

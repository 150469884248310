const School = (props) => {
  // const myStyle = {
  //   width: "100%",
  //   // height: "100vh",
  //   backgroundImage: 'url("/assets/${props.img}")',
  //   backgroundSize: "cover",
  //   backgroundPosition: "center",
  // };
  return (
    <div>
      <div className="text-center text-light-text mt-12">
        <img src={props.img} className="bg-cover w-full" />
        <h1 className=" text-center font-semibold align-middle p-1">
          {props.title}
        </h1>
        <p className="text-base  py-3">{props.content}</p>
      </div>
    </div>
  );
};

export default School;

const Information = (props) => {
  return (
    <div className=" py-12 text-center mx-auto lg:w-[60%]">
      <h1 className="  font-semibold text-3xl text-dark-text">{props.title}</h1>
      <p className="text-base py-3 text-light-text">{props.content}</p>
    </div>
  );
};

export default Information;
// px-12 mt-24 mb-8

import { Grid, Paper } from "@mantine/core";
import Ads from "../../components/modules/content/Ads";
// import Bread from "../../components/modules/content/bread";
import MainContent from "../../components/modules/content/MainContent";
import MainImg from "../../components/modules/content/MainImg";
import SideMenu from "../../components/modules/content/SideMenu";

const Contentpage = () => {
  return (
    <main className="pb-md">
      <MainImg />

      <section
        className="content mx-lg px-9 pb-9"
        style={{ marginTop: "-48px" }}
      >
        <Paper className="rounded-md shadow-md " p="sm">
          <Grid mt="md">
            <Grid.Col
              lg={3}
              md={3}
              sm={0}
              xs={0}
              className="section1 h-full w-full lg:pt-12 hidden lg:block "
            >
              <SideMenu />
            </Grid.Col>

            <Grid.Col
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className="section2 pt-12 text-light-text"
            >
              <MainContent />
            </Grid.Col>

            <Grid.Col
              lg={3}
              md={3}
              sm={6}
              xs={6}
              className="section3 p-3 md:pt-12"
            >
              <Ads />
            </Grid.Col>
          </Grid>
        </Paper>
      </section>
    </main>
  );
};

export default Contentpage;

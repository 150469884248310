import './App.scss';
import { Provider } from "react-redux";
import { store } from "./store/store";
import { MainContainer } from "./hoc/main";
import MainRoute from "./routes";
import { LandingLayout } from './layouts/Landing.layout';

function App() {
  return (
    <Provider store={store}>
      <MainContainer >
        <LandingLayout />
      </MainContainer>
    </Provider >
  );
}

export default App;

import { Grid } from "@mantine/core";
import Bgreact from "../../common/Bgreact";
import Information from "../../common/Information";

export const WhyUs = () => {
  const bg = [
    {
      title: "PARENT PERSPECTIVES",
      content:
        "We are the only school in Zurich offering the International Baccalaureate (IB) Programme for Primary",
      img: "/assets/rect1.png",
    },
    {
      title: "FAMILY TRANSITIONS ",
      content:
        "We are the only school in Zurich offering the International Baccalaureate (IB) Programme for Primary",
      img: "/assets/rect2.png",
    },
    {
      title: "STUDENT GRADUATION",
      content:
        "We are the only school in Zurich offering the International Baccalaureate (IB) Programme for Primary",
      img: "/assets/rect3.png",
    },
  ];
  return (
    <div>
      <div className="text-center mx-auto">
        <div className="mt-12">
          <Information
            title="Why Arniko"
            content=" ICS provides an empowering learning environment where all members of
          the community are <br />
          challenged to achieve their potential, encouraged to pursue their
          passions, and expected to
          <br /> fulfil their responsibilities. Numerous programmes support
          students and parents through all <br />
          stages of their ICS journey."
          />
        </div>
        {/* <h1 className="mt-[78px] mb-2 font-pop font-semibold text-3xl">
          Why Arniko
        </h1>
        <p className="text-base font-normal mb-4 p-4">
          ICS provides an empowering learning environment where all members of
          the community are <br />
          challenged to achieve their potential, encouraged to pursue their
          passions, and expected to
          <br /> fulfil their responsibilities. Numerous programmes support
          students and parents through all <br />
          stages of their ICS journey.
        </p> */}
      </div>
      <Grid>
        {bg.map((data, index) => (
          <Grid.Col lg={4} md={6} sm={12} key={index} className="p-1">
            <Bgreact title={data.title} content={data.content} img={data.img} />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};

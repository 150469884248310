import { useParams } from "react-router-dom";

import Culture from "../../../pages/auth/Culture";
import Primary from "../../../pages/auth/Primary";
import Middle from "../../../pages/auth/Middle";
import Senior from "../../../pages/auth/Senior";

const MainContent = () => {
  const { category } = useParams();

  switch (category) {
    case "Primary":
      return <Primary />;
    case "middle":
      return <Middle />;
    case "senior":
      return <Senior />;
    // case "culture":
    //   return <Culture />;

    default:
      return <Primary />;
  }
};

export default MainContent;
